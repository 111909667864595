<template>
  <!-- 表单营销推广roi弹窗组件 -->
  <div class="box_wrapper" v-loading="cardLoading">
    <!-- 时间选择菜单-->
    <div class="time_menu">
      <span>时间：</span>
      <!-- 快捷时间筛选 -->
      <el-button-group>
        <el-button
          :type="timeMenuActive === item.value ? 'primary' : ''"
          size="mini"
          v-for="item in timeMenuArr"
          :key="item.id"
          @click="(timeMenuActive = item.value), timeMunuHandle()"
        >
          {{ item.name }}
        </el-button>
      </el-button-group>

      <!-- 日期选择 -->
      <el-date-picker
        v-model="timePickerValueArr"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="mini"
        class="time_picker"
        value-format="yyyy-MM-dd"
        @change="timePickerHandle"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>

    <!-- tab menu -->
    <div class="tab_menu">
      <template v-for="(item, index) in campaignFormList">
        <span
          class="tab_menu_item"
          @click="(activeTabIndex = index), tabClick(item)"
          :style="{ color: activeTabIndex === index ? '#0000ff' : '' }"
          :key="index"
          >{{ item.name }}</span
        >
      </template>
    </div>

    <!-- desc bar -->
    <div class="desc_ber">
      <div
        v-for="(item, index) in descBarList"
        :key="index"
        class="desc_bar_item"
      >
        <span>{{ item.count }}</span>
        <span>{{ item.name }}</span>
      </div>
    </div>

    <!-- chart dom -->
    <div ref="chartRef" class="chart_dom"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getformPromotionRoi } from "./api";
export default {
  props: {
    // 请求参数
    campaignid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 图表初始化
      myChart: "",
      // current active tab index
      activeTabIndex: 0,
      //   图标顶部描述信息List
      descBarList: [
        {
          id: "0",
          name: "访问数量",
          count: "0次",
        },
        {
          id: "1",
          name: "注册数量",
          count: "0次",
        },
        {
          id: "2",
          name: "客户转化数量",
          count: "0次",
        },
        {
          id: "3",
          name: "赢得客户机会总额",
          count: "CNY 0",
        },
        {
          id: "4",
          name: "投资回报率",
          count: "-100%",
        },
      ],
      // 表单推广列表
      campaignFormList: {},
      cardLoading: true, //当前卡片 loading
      // 时间选择菜单数组
      timeMenuArr: [
        {
          id: 1,
          name: "最近7天",
          value: "7",
        },
        {
          id: 2,
          name: "最近30天",
          value: "30",
        },
      ],
      // 当前激活的按钮索引
      timeMenuActive: "7",
      // 日期选择器选择的日期数组
      timePickerValueArr: [],
      // 表单营销推广id(选择单个推广传值)
      campaignformid: "",
      /**
       * 限制日期选择范围 前后1年
       */
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let month1 = 30 * 24 * 3600 * 1000; //一个月
          let lastYear = curDate - month1 * 12;
          let nextYear = curDate + month1 * 12;

          // 当前time
          let nowSelectTime = time.getTime();
          return nowSelectTime > nextYear || nowSelectTime < lastYear;
        },
      },
    };
  },
  mounted() {
    // 初始化图表
    this.myChart = echarts.init(this.$refs.chartRef);
    this.getformPromotionRoiRequest();
  },
  methods: {
    tabClick(e) {
      this.campaignformid = e.id;
      this.getformPromotionRoiRequest();
    },

    /**
     * 表单推广ROI请求参数
     * campaignid 市场活动表id
     * campaignformid 表单营销推广id(选择单个推广传值)
     */
    async getformPromotionRoiRequest() {
      this.cardLoading = true;

      // 左侧菜单选择的快捷时间
      let campaignformid = this.campaignformid ?? "";
      let campaignid = campaignformid ? "" : this.campaignid;
      let timePickerValueArr = this.timePickerValueArr,
        startTime,
        endTime;
      if (timePickerValueArr) {
        (startTime = timePickerValueArr[0]), (endTime = timePickerValueArr[1]);
      } else {
        this.timeMenuActive = "7";
      }

      // 如果用户选择了开始和结束时间则清空左侧快捷时间
      if (startTime && endTime) {
        this.timeMenuActive = "";
      }

      let {
        data: {
          chartData,
          campaignFormList,
          browseNum,
          submitNum,
          khzhsNum,
          sydywjhzz,
          tzhbl,
        },
      } = await getformPromotionRoi({
        // 单个推广的 可以不用传市场活动的id（查询单个推广用接口返回的推广id）
        campaignid: campaignid,
        // 查询总览可以不需要传递，传了也没事
        campaignformid: campaignformid,
        startTime, //开始时间
        endTime, //结束时间
        fewdays: this.timeMenuActive, //获取最近多少天
      });

      this.cardLoading = false;

      /**
       * 表单推广列表
       * 只有首次加载的总览才有tab菜单返回
       */
      if (campaignFormList) {
        this.campaignFormList = [{ name: "总览", id: "" }, ...campaignFormList];
      }
      // 顶部数量显示
      let descArr = { browseNum, submitNum, khzhsNum, sydywjhzz, tzhbl };

      this.runChart(chartData, descArr);
    },
    /**
     * 运行图表
     */
    runChart(chartData, descArr) {
      let option = {
        // 设置距离盒子默认间距
        grid: {
          left: "0",
          right: "0",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["5.25", "5.26"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [150, 230],
            type: "line",
            smooth: true,
            name: "访问数量",
          },
          {
            data: [160, 280],
            type: "line",
            smooth: true,
            name: "注册数量",
          },
        ],
        //   需要和 series 中的 name一一对应上才能显示
        legend: {
          data: ["访问数量", "注册数量"],
          type: "plain",
          pageIconColor: this.themeColor === "dark" ? "#aaa" : "#2f4554",
          pageIconInactiveColor:
            this.themeColor === "dark" ? "#2f4554" : "#aaa",
          bottom: 20,
          pageTextStyle: {
            color: this.themeColor === "dark" ? "#B9B8CE" : "#6E7079",
          },
        },
      };

      // 底部时间 时间数组
      option.xAxis.data = chartData.dateData;
      // 访问数量数组
      option.series[0].data = chartData.fwslData;
      // 注册数量数组
      option.series[1].data = chartData.zcslData;

      // 顶部数量显示
      let descBarList = this.descBarList;
      this.descBarList = descBarList.map((item) => {
        switch (item.id) {
          case "0":
            item.count = descArr.browseNum;
            break;
          case "1":
            item.count = descArr.submitNum;

            break;
          case "2":
            item.count = descArr.khzhsNum;
            break;
          case "3":
            item.count = descArr.sydywjhzz;
            break;
          case "4":
            item.count = descArr.tzhbl;
            break;
        }
        return item;
      });

      option && this.myChart.setOption(option);
    },
    // 右侧时间选择点击事件
    timePickerHandle() {
      // 清空左侧快捷时间的高亮显示
      this.timeMenuActive = "";
      this.getformPromotionRoiRequest();
    },
    // 左侧快捷时间点击
    timeMunuHandle() {
      this.timePickerValueArr = [];
      this.getformPromotionRoiRequest();
    },
  },
};
</script>

<style lang="scss" scoped>
.box_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;

  .time_menu {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .time_picker {
      margin-left: 10px;
        display: flex;
    align-items: center;
    }
  }

  .tab_menu {
    align-self: flex-start;

    margin-right: 5px;
    margin-bottom: 10px;
    .tab_menu_item {
      cursor: pointer;
      user-select: none;
      &:hover {
        color: #e5851a;
      }
      &:not(:last-child)::after {
        content: "|";
        font-size: 12px;
        margin: 2px;
      }
    }
  }
  .desc_ber {
    display: flex;
    > .desc_bar_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
    }
  }
  .chart_dom {
    width: 100%;
    height: 400px;
  }
}
::v-deep  .el-input__icon {
    line-height: 20px !important;
}
</style>


